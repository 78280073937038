import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import MarketingLayout from '@components/MarketingLayout';
import SliceZone from '@components/SliceZone';
import linkResolver from '@utils/linkResolver';
import { PartnerPageTemplateQuery } from '@typings/prismic-types';

type Props = PageProps<PartnerPageTemplateQuery>;

export function PartnerPageTemplate({ data }: Props) {
  if (!data) return null;

  const partnerPage = data.prismicPartnerPage;
  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  const { lang, type, url } = partnerPage;
  const alternateLanguages = partnerPage.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  return (
    <MarketingLayout
      banner={data.prismicBanner}
      nav={nav}
      footer={footer}
      activeDocMeta={activeDoc}
      data={partnerPage.data}
    >
      <SliceZone slices={partnerPage.data.body} />
    </MarketingLayout>
  );
}

export const query = graphql`
  query PartnerPageTemplate($uid: String, $lang: String) {
    prismicPartnerPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      url
      type
      uid
      id
      lang
      alternate_languages {
        id
        type
        lang
      }
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        page_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
        body {
          ...PrismicPartnerCustomerLogosFragment
          ...PrismicPartnerTestimonialCarouselFragment
          ...PrismicPartnerFragment
          ...PrismicPartnerWistiaVideoAndTextSideBySideFragment
          ...PrismicPartnerCardsSectionFragment
        }
      }
    }
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(PartnerPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
