/**
 * Layout component for Marketing pages and Homepage.
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '@components/Banner';
import Footer from '@components/Footer';
import Nav from '@components/Nav';
import Seo from '@components/Seo';
import {
  NavFragmentFragment,
  FooterFragmentFragment,
  MarketingPageTemplateQuery,
  HomepageTemplateQuery,
  PrismicAlternateLanguageType,
  PrismicBannerFragmentFragment,
  PartnerPageTemplateQuery,
  GrowthCalculatorQuery,
} from '@typings/prismic-types';
import './styles.scss';

type Props = {
  children: React.ReactNode;
  banner: PrismicBannerFragmentFragment;
  nav: NavFragmentFragment;
  footer: FooterFragmentFragment;
  activeDocMeta?: {
    lang?: string;
    type?: string;
    url?: string;
    alternateLanguages: PrismicAlternateLanguageType[];
  };
  data?:
    | MarketingPageTemplateQuery['prismicMarketingPage']['data']
    | HomepageTemplateQuery['prismicHomepage']['data']
    | PartnerPageTemplateQuery['prismicPartnerPage']['data']
    | GrowthCalculatorQuery['prismicGrowthCalculator']['data'];
};

const MarketingLayout = ({
  children,
  banner,
  nav,
  footer,
  data,
  activeDocMeta,
}: Props) => {
  const isHomepage =
    activeDocMeta && (activeDocMeta.url === '/' || activeDocMeta.url === '');
  return (
    <div className="font-neue-haas-text text-gray-900">
      <Seo
        title={data ? data.page_title.text : `Pry.co`}
        description={data ? data.page_description.text : `Pry`}
        image={data ? data.page_image.url : null}
      />
      <Helmet>
        <link
          rel="canonical"
          href={`https://pry.co${
            activeDocMeta && activeDocMeta.url ? activeDocMeta.url : ``
          }`}
        />
      </Helmet>
      <Banner banner={banner} />
      <Nav nav={nav} />
      <main>
        <div
          className={`container-with-padding`}
          style={
            !isHomepage
              ? {
                  overflow: 'hidden',
                }
              : {}
          }
        >
          {children}
        </div>
      </main>
      <Footer footer={footer} />
    </div>
  );
};

MarketingLayout.propTypes = {
  children: Number,
};

export default MarketingLayout;
